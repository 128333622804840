///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules. Don't use a mixin where you could use
/// @extend instead.
///

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: true) {
  @if ($value == true) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input checkbox and input radio element
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 5px) {
  // hide the actual element
  position: absolute;
  left: -9999px;
  ~ label:not(.no-js-element),
  ~ .label {
    @include icon($icon);
    &::before {
      margin-right: $padding;
      margin-top: -2px;
    }
  }
  &:checked {
    ~ label:not(.no-js-element),
    ~ .label {
      @include icon($icon + '--' + $suffix);
    }
  }
  // Add focus styles
  &:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }
}

///
/// Center Element on any Axis
/// https://css-tricks.com/snippets/sass/centering-mixin/them if it's important.
// NOTE: assumes parent element has position: relative;
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    @include transform(translate(-50%, -50%));
    top: 50%;
    left: 50%;
  } @else if ($horizontal) {
    @include transform(translate(-50%, 0));
    left: 50%;
  } @else if ($vertical) {
    @include transform(translate(0, -50%));
    top: 50%;
  }
}

@mixin destroy-centerer() {
  @include transform(none);
  position: static;
  top: auto;
  left: auto;
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin content-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;

  // specific/locale sitewide container maximum width
  @if $xxxlarge-site-width == true {
    max-width: $xxxlarge-max-width;
  } @else {
    max-width: $max-width;
  }
}

// New styleguide / Link style mixins - applied in helper classes
// Variants are meant to be loaded after base mixin
// Link base
@mixin text-link {
  font-family: $main-font;
  font-size: 14px;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:active,
  &:visited {
    color: $color-black-v2;
    text-decoration: none;
  }
}

// Link - Style 1
@mixin text-link--style-1 {
  color: $color-black-v2;
  border-bottom: 1px solid $color-black-v2;
  &:hover {
    color: $color-black-v2;
    font-family: $medium-font-family;
  }
}

// Link - Style 2
@mixin text-link--style-2 {
  color: $color-green-v2;
  border-bottom: 1px solid $color-green-v2;
  &:hover {
    color: $color-green-v2;
    font-family: $medium-font-family;
  }
}

// Gnav Link Style - 2020
@mixin gnav-text-link {
  color: $color-black-v2;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.89;
  padding: 0 $gnav-mobile-hpad--outer;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0.03em;
  @include breakpoint($landscape-up) {
    display: inline-flex;
    height: auto;
    line-height: 1.5;
    font-size: 26px;
    letter-spacing: 0;
    padding: 0;
  }
  &:focus,
  &:hover {
    font-weight: 700;
    color: $color-black-v2;
    text-decoration: none;
  }
}

// Gnav Link Style - 2020
@mixin gnav-mobile-back {
  cursor: pointer;
  position: fixed;
  top: $offerbar-mobile-height;
  #{$ldirection}: $container-pad-small;
  text-decoration: none;
  font-weight: 700;
  background-color: $color-white;
  height: $navbar-mobile-height - 1;
  line-height: 1;
  min-width: 148px;
  @include breakpoint($landscape-up) {
    top: $offerbar-height;
    height: $navbar-height - 1;
  }
  .site-header-formatter--sticky &,
  .gnav-offers-hidden & {
    top: 0;
  }
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

// Container related verical margin rules
@mixin container-vertical-rules($dir: top) {
  .container-vertical-#{$dir}-margin {
    &--20 {
      margin-#{$dir}: 20px;
    }
    &--40 {
      margin-#{$dir}: 40px;
    }
    &--80 {
      margin-#{$dir}: 80px;
    }
    &--120 {
      margin-#{$dir}: 120px;
    }
  }
}

// Container related width rules
@mixin container-max-width-full {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 100%;
}

@mixin container-max-width-medium {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: 1300px;
  width: 100%;
}

@mixin container-max-width-large {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width-large;
  width: 100%;
}

@mixin container-max-width-xlarge {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  max-width: $container-max-width-xlarge;
  width: 100%;
}

// New styleguide / Form elements mixins - applied in helper classes
@mixin input-form {
  border: 1px solid $color-black-v2;
  color: $color-dark-gray-v2;
  display: block;
  float: none;
  font-family: $font--gotham;
  font-size: 21px;
  height: 60px;
  line-height: 60px;
  margin: 0 0 15px;
  padding: 0 15px;
  vertical-align: middle;
  &:hover {
    border: 1px solid $color-green-v2;
    color: $color-green-v2;
    font-family: $font--gotham-medium;
  }
  &:focus,
  &:active,
  &:valid {
    background-color: $color-white;
    color: $color-black-v2;
    font-family: $font--gotham-medium;
    @include input-placeholder {
      color: $color-black-v2;
    }
  }
}

@mixin input-error {
  border: 1px solid $color-error-v2;
  color: $color-error-v2;
  font-family: $font--gotham-medium;
  @include input-placeholder {
    color: $color-error-v2;
  }
  &:hover {
    border: 1px solid $color-error-v2;
    color: $color-error-v2;
  }
}

@mixin btn {
  @include font-smoothing;
  @include appearance(none);
  font-family: $medium-font-family;
  display: inline-block;
  background-color: $color-black-v2;
  color: $color-white;
  padding: 0 30px;
  height: 60px;
  min-width: 220px;
  line-height: 60px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: lighter;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  vertical-align: bottom;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  &:hover,
  &:active {
    text-decoration: none;
    background-color: $color-green-v2;
  }
}
/* Review Stars */
@mixin power-review-stars(
  $size: 16px,
  $classprefix: 'pr-star-v4',
  $foreground: '/sites/darphin/themes/darphin_base/img/icons/src/star.svg',
  $background: '/sites/darphin/themes/darphin_base/img/icons/src/star.svg'
) {
  $pr-count: (0, 25, 50, 75, 100);
  .pr-rating-stars {
    // base star style
    .#{$classprefix} {
      height: #{$size};
      width: #{$size};
      @content;
      &:before,
      &:after {
        height: #{$size};
        width: #{$size};
        background-size: #{$size};
      }
    }
    // filled styles
    @each $pr-value in $pr-count {
      .#{$classprefix}-#{$pr-value}-filled {
        height: $size;
        width: $size;
        border-radius: 0;
        padding: 0;
        background: none;
        &:before,
        &:after {
          content: '';
          display: block;
          height: $size;
          width: $size;
        }
        &:before {
          position: absolute;
          overflow: hidden;
          width: calc(#{$size} * (#{$pr-value}/ 100));
          background-image: url($foreground);
          background-size: $size $size;
        }
        &:after {
          opacity: 0.4;
          background-image: url($background);
        }
      }
    }
  }
}
// Loaders
@mixin loader($color: $black) {
  $loader-pos: 9999px;
  $loader-dot-spacing: 24px;
  $loader-dot1: $loader-pos - $loader-dot-spacing;
  $loader-dot2: $loader-pos;
  $loader-dot3: $loader-pos + $loader-dot-spacing;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: $color;
  color: $color;
  display: none;
  position: relative;
  right: $loader-pos;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.5s;
  animation-timing-function: step-end;
  margin: 40px auto auto;
  &::before,
  &::after {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: $color;
    color: $color;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
    animation-timing-function: step-end;
  }
  &::after {
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 1s;
    animation-timing-function: step-end;
  }
  @keyframes dot-pulse-before {
    0% {
      box-shadow: $loader-dot1 0 0 0;
    }
    30% {
      box-shadow: $loader-dot1 0 0 1px;
    }
    60%,
    100% {
      box-shadow: $loader-dot1 0 0 0;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: $loader-dot2 0 0 0;
    }
    30% {
      box-shadow: $loader-dot2 0 0 1px;
    }
    60%,
    100% {
      box-shadow: $loader-dot2 0 0 0;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: $loader-dot3 0 0 0;
    }
    30% {
      box-shadow: $loader-dot3 0 0 1px;
    }
    60%,
    100% {
      box-shadow: $loader-dot3 0 0 0;
    }
  }
}

// checkout related mixins

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $main-font;
  font-size: get-rem(30px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $main-font;
  font-size: get-rem(18px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}

// sub header/title style - small version - style 2
@mixin checkout-sub-heading--small-style2 {
  font-family: $bold-font-family;
  font-size: get-rem(12px);
  letter-spacing: 1.7px;
  line-height: get-line-height(12px, 20px);
  text-transform: uppercase;
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $main-font;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for paragraphs/body text bold
@mixin checkout-body-text--bold {
  font-family: $bold-font-family;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 14px);
}

// 2023 font styles

@mixin headline-1-lowercase {
  font-family: $medium-font-family;
  font-size: get-rem(25px);
  line-height: get-line-height(25px, 30px);
  letter-spacing: normal;
  -webkit-text-stroke: 0.8px;
  @include breakpoint($landscape-up) {
    font-size: get-rem(36px);
    line-height: get-line-height(36px, 40px);
    -webkit-text-stroke: 1.1px;
  }
}

@mixin headline-2-lowercase {
  font-family: $medium-font-family;
  font-size: get-rem(20px);
  line-height: get-line-height(20px, 25px);
  letter-spacing: normal;
  -webkit-text-stroke: 0.8px;
  @include breakpoint($landscape-up) {
    font-size: get-rem(30px);
    line-height: get-line-height(30px, 35px);
    -webkit-text-stroke: 1.1px;
  }
}

@mixin headline-3-lowercase {
  font-family: $medium-font-family;
  font-size: get-rem(16px);
  line-height: get-line-height(16px, 20px);
  letter-spacing: normal;
  -webkit-text-stroke: 0.7px;
  @include breakpoint($landscape-up) {
    font-size: get-rem(22px);
    line-height: get-line-height(22px, 25px);
    -webkit-text-stroke: 0.9px;
  }
}

@mixin headline-4-lowercase {
  font-family: $medium-font-family;
  font-size: get-rem(14px);
  line-height: get-line-height(14px, 20px);
  letter-spacing: normal;
  -webkit-text-stroke: 0.6px;
}

@mixin header-nav-lowercase {
  font-family: $main-font;
  font-size: get-rem(17px);
  line-height: get-line-height(17px, 20px);
  letter-spacing: normal;
}

@mixin body-1-lowercase {
  font-family: $main-font;
  font-size: get-rem(14px);
  line-height: get-line-height(14px, 22px);
  letter-spacing: normal;
  @include breakpoint($landscape-up) {
    line-height: get-line-height(14px, 20px);
  }
}

@mixin body-2 {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 15px);
  letter-spacing: normal;
}

@mixin body-3 {
  font-family: $main-font;
  font-size: get-rem(10px);
  line-height: get-line-height(10px, 10px);
  letter-spacing: normal;
}

